import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";
import { openDrawerLgAndUp } from "src/slices/ui";
import { useDispatch, useSelector } from "src/store";

import { useMounted } from "./use-mounted";

export const useOpenDrawer = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMounted = useMounted();
    const lgUpDrawerOpen = useSelector(state => state.ui.lgUpDrawerOpen);
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'), {
        noSsr: true
    });


    useEffect(() => {
        if (isMounted() && lgUp && !lgUpDrawerOpen) {
            dispatch(openDrawerLgAndUp());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lgUp]);
}