import Icon, { IconProps } from 'components/dist/atoms/Icon';
import Text from 'components/dist/atoms/Text';
import { getOneDriveDocumentAppLabel } from 'src/utils/document/get-one-drive-document-app-label';

type Platform = 'WEB' | 'DESKTOP' | "WEB_ANONYMOUS";

interface PreviewReadOnlyProps {
    extension: string;
    platform: Platform;
}

type OfficeType = 'Word' | 'Excel' | 'Powerpoint';

const TypeIcon: Record<OfficeType, IconProps['name']> = {
    Word: 'MsWord',
    Excel: 'MsExcel',
    Powerpoint: 'MsPowerpoint',
};

const PlatformLabel: Record<PreviewReadOnlyProps['platform'], {
    primary: string,
    secondary: string
}> = {
    WEB: {
        primary: 'Edit in Web',
        secondary: null
    },
    DESKTOP: {
        primary: 'Edit in Desktop',
        secondary: null
    },
    WEB_ANONYMOUS: {
        primary: 'Edit in Web',
        secondary: '(Guest User)'
    },
};

export const PreviewReadOnlyEdit = (props: PreviewReadOnlyProps) => {
    const { extension } = props;
    const type = getOneDriveDocumentAppLabel(extension);

    if (!type) {
        return <div />;
    }

    return (<>
        <Icon name={TypeIcon[type]} width={20} height={20} className='shrink-0' />
        <Text className='shrink-0' variant='inherit' size='inherit'>
            {PlatformLabel[props.platform].primary}
        </Text>
        {PlatformLabel[props.platform].secondary && <Text className='shrink-0' variant='secondary' size='inherit'>
            {PlatformLabel[props.platform].secondary}
        </Text>}
    </>);
};