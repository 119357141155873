import GlobalShoeBox from 'components/dist/organisms/GlobalShoeBox';
import { JestTestId } from 'src/constants/tests';

import { useDashboardSidebarShoebox } from './dashboard-sidebar-shoebox.state';

interface DashboardSidebarShoeboxProps {
    size?: 'default' | 'small';
}

export const DashboardSidebarShoebox = (props: DashboardSidebarShoeboxProps) => {
    const state = useDashboardSidebarShoebox();

    return <GlobalShoeBox
        team={state.team}
        onOpenFileDoubleClick={state.onOpenFileDoubleClick}
        open={state.isDialogOpen}
        onOpenChange={state.onDialogOpenChange}
        loadingLoans={state.isLoadingLoans}
        secureUploadPageLink={state.secureUploadPageLink}
        loadingShoeBoxFiles={state.isLoadingShoeBoxFiles}
        me={state.me}
        selectedTeamUserIds={state.selectedTeamUserIds}
        onTeamFilterChange={state.onTeamFilterChange}
        hideLoanFilter={state.hideLoanFilter}
        onGoToLoanCreatePage={state.onGoToLoanCreatePage}
        onGoToLoan={state.onGoToLoan}
        onMoveShoeBoxFilesToLoan={state.onMoveShoeBoxFilesToLoan}
        onCancelUploadingFile={state.onCancelUploadingFile}
        onAddFilesToShoeBox={state.onAddFilesToShoeBox}
        onDeleteShoeBoxFiles={state.onDeleteShoeBoxFiles}
        onAddMeToLoan={state.onAddMeToLoan}
        onOpenShoeBoxFileInNewTab={state.onOpenShoeBoxFileInNewTab}
        onRenameShoeBoxFile={state.onRenameShoeBoxFile}
        loans={state.loans}
        totalItems={state.totalItems}
        folders={state.folders} >
        <GlobalShoeBox.Trigger
            data-testid={JestTestId.GlobalShoeBoxTrigger}
            size={props.size} />
    </GlobalShoeBox>
}