
import FullScreenDocumentViewer from 'components/dist/organisms/FullScreenDocumentViewer';
import React from 'react';

import { useShoeboxItemViewer } from '../shoebox-item-viewer.state';
import { ShoeBoxItemViewerElementActions } from './shoebox-item-viewer-actions-element.component';
import { ShoeBoxItemViewerShoeboxItemActions } from './shoebox-item-viewer-actions-shoebox-item.component';


export const ShoeBoxItemViewerActions = () => {
    const state = useShoeboxItemViewer();

    return <FullScreenDocumentViewer.Actions>
        {!state.isElementASharedLink && !state.actionsDisabled && <ShoeBoxItemViewerShoeboxItemActions />}
        {state.element && state.loan && state.isElementFullScreenPreview && <ShoeBoxItemViewerElementActions />}
    </FullScreenDocumentViewer.Actions>
}

ShoeBoxItemViewerActions.displayName = FullScreenDocumentViewer.Actions.displayName;