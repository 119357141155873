import Icon from 'components/dist/atoms/Icon';
import { TaskGroup } from "src/constants/ui";
import { ValueOf } from "src/types/common";

const IconSizeMap = {
    AssignQuestionTask: {
        default: {
            width: 22,
            height: 16,
            strokeWidth: 1.5,
        },
        small: {
            width: 14,
            height: 14,
            strokeWidth: 2,
        },
    },
    AnswerQuestionTask: {
        default: {
            width: 20,
            height: 20,
            strokeWidth: 1.5,
        },
        small: {
            width: 14,
            height: 14,
            strokeWidth: 2,
        },
    },
    ApproveAnswerTask: {
        default: {
            width: 24,
            height: 24,
            strokeWidth: 1.5,
        },
        small: {
            width: 14,
            height: 14,
            strokeWidth: 2,
        },
    },
    ShoeBoxTask: {
        default: {
            width: 20,
            height: 20,
            strokeWidth: 1.5,
        },
        small: {
            width: 14,
            height: 14,
            strokeWidth: 2,
        },
    },
    AssignSigneeTask: {
        default: {
            width: 22,
            height: 16,
            strokeWidth: 1.5,
        },
        small: {
            width: 16,
            height: 12,
            strokeWidth: 2,
        },
    },
    SignTask: {
        default: {
            width: 20,
            height: 20,
            strokeWidth: 1.5,
        },
        small: {
            width: 14,
            height: 14,
            strokeWidth: 2,
        },
    },
    FillTask: {
        default: {
            width: 20,
            height: 20,
            strokeWidth: 1.5,
        },
        small: {
            width: 14,
            height: 14,
            strokeWidth: 2,
        },
    },
    FillFormTask: {
        default: {
            width: 20,
            height: 20,
            strokeWidth: 1.5,
        },
        small: {
            width: 14,
            height: 14,
            strokeWidth: 2,
        },
    },
};

const TaskTypeIcon = {
    [TaskGroup.AssignQuestionTask]: "AddUser",
    [TaskGroup.AnswerQuestionTask]: "Upload",
    [TaskGroup.ApproveAnswerTask]: "Check",
    [TaskGroup.ShoeBoxTask]: "Box",
    [TaskGroup.AssignSigneeTask]: "AddUser",
    [TaskGroup.SignTask]: "SelfSign",
    [TaskGroup.FillTask]: "FillableForm",
    [TaskGroup.FillFormTask]: "FillableForm",
}

type Props = {
    type: ValueOf<typeof TaskGroup>,
    size?: 'default' | 'small',
}

export const TaskGroupIcon = (props: Props) => {
    const { type, size = 'default' } = props;
    if (!TaskTypeIcon[type]) {
        return null;
    }
    const { width, height, strokeWidth } = IconSizeMap[type][size];
    return <Icon name={TaskTypeIcon[type]} width={width} height={height} strokeWidth={strokeWidth} />;
}