import AlertDialog from "components/dist/atoms/AlertDialog";
import Checkbox from "components/dist/atoms/Checkbox";
import Icon from "components/dist/atoms/Icon";
import Label from "components/dist/atoms/Label";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import Textarea from "components/dist/atoms/Textarea";
import { useRef, useState } from "react";

interface FormElementStatusRejectConfirmProps {
    open: boolean;
    onOpenChange: (isOpen: boolean) => void;
    onConfirm: (args: { note: string, includeWithMessage: boolean }) => void;
    title: string;
    id: string;
    loanId: string;
}

export const FormElementStatusRejectConfirm = (props: FormElementStatusRejectConfirmProps) => {
    const [formState, setFormState] = useState<{ includeWithMessage: boolean, note: string }>({
        note: '',
        includeWithMessage: false,
    });

    const onContinueClick = () => {
        props.onConfirm(formState);
        setFormState({ note: '', includeWithMessage: false });
    }
    return <AlertDialog
        onOpenChange={props.onOpenChange}
        open={props.open}>
        <AlertDialog.Content
            aria-label="Reject Form Element"
            className="pt-8 pb-5 max-w-md p-6">
            <AlertDialog.Header className="px-10 flex-1 items-center justify-center">
                <Icon name="BigExclamation" className='text-black-10' />
                <Text
                    size="sm"
                    center
                    as="div" className="pt-8">
                    Reject {props.title}
                </Text>
            </AlertDialog.Header>
            <Stack space="sm">
                <Text
                    className="text-left"
                    size="sm"
                    as="div">
                    Please give your reason for rejecting {props.title} <Text
                        variant="secondary"
                        size="inherit" as="span">(Optional)</Text>
                </Text>
                <Stack space="sm">
                    <Textarea className="scrollbar-stable h-20"
                        value={formState.note}
                        maxLength={150}
                        onInput={(e: any) => setFormState(prevState => ({ ...prevState, note: e.target.value }))}
                        placeholder="Enter here"
                        id="rejectionReason"
                        wrapClassName="relative"
                        name="rejectionReason" >
                        <Text
                            size="xs"
                            variant={formState.note.length >= 150 ? "destructive" : "secondary"}
                            className="absolute  bg-white p-1 rounded-sm bottom-0 right-0 mr-3 mb-1">
                            {formState.note.length}/150
                        </Text>
                    </Textarea>
                    <Stack
                        row
                        space="sm"
                        className='flex item-center'>
                        <Checkbox
                            onCheckedChange={(checked: boolean) => setFormState(prevState => ({ ...prevState, includeWithMessage: checked }))}
                            checked={formState.includeWithMessage}
                            size="sm"
                            id="includeWithMessage"
                            name="includeWithMessage"
                            className='bg-white' />
                        <Label
                            variant="secondary"
                            htmlFor='includeWithMessage'
                            className='items-center flex'>Include with message</Label>
                    </Stack>
                </Stack>
            </Stack>
            <AlertDialog.Footer className="sm:justify-center mt-10 justify-center gap-6">
                <AlertDialog.Cancel className='min-w-32'>
                    Cancel
                </AlertDialog.Cancel>
                <AlertDialog.Action className='min-w-32' onClick={onContinueClick}>
                    Continue
                </AlertDialog.Action>
            </AlertDialog.Footer>
        </AlertDialog.Content>
    </AlertDialog>
}