import Drawer from 'components/dist/atoms/Drawer';
import DropdownMenu from 'components/dist/atoms/DropdownMenu';
import Icon from 'components/dist/atoms/Icon';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import FullScreenDocumentViewer from 'components/dist/organisms/FullScreenDocumentViewer';
import React from 'react';
import { useUploadFormElementContext } from 'src/contexts/upload-form-element-context/upload-form-element-context';

import { useShoeBoxItemViewerContext } from '../shoebox-item-viewer.context';
import { useShoeboxItemViewer } from '../shoebox-item-viewer.state';


export const ShoeBoxItemViewerShoeboxItemActions = () => {
    const state = useShoeboxItemViewer();
    const uploadFormElementContext = useUploadFormElementContext();
    const context = useShoeBoxItemViewerContext();
    const DropdownMenuDrawer = state.isDesktop ? DropdownMenu : Drawer;

    const onMoveClick = () => {
        if (state.shoeBoxItemData?.loanRole?.loan) {
            uploadFormElementContext.onCopyMove({ operation: "MOVE", type: "SHOEBOX_ITEMS", elements: [state.shoeBoxItemData], loanId: state.shoeBoxItemData.loanRole.loan });

        } else {
            context.setMoveToLoanShoeBoxFile(state.shoeboxFile);
        }
    }

    return <>
        <Tooltip>
            <Tooltip.Trigger asChild>
                <FullScreenDocumentViewer.ActionButton
                    className='items-center'
                    onClick={onMoveClick}
                >
                    <Icon name="FolderMoveUp" width={20} height={20} />
                </FullScreenDocumentViewer.ActionButton>
            </Tooltip.Trigger>
            <Tooltip.Content
                align="end"
                side="bottom"
                alignOffset={0}
            >
                Move
            </Tooltip.Content>
        </Tooltip>
        <DropdownMenuDrawer>
            <DropdownMenuDrawer.Trigger asChild>
                <FullScreenDocumentViewer.ActionButton>
                    <Icon name="MoreVert" width={20} height={20} />
                </FullScreenDocumentViewer.ActionButton>
            </DropdownMenuDrawer.Trigger>
            <DropdownMenuDrawer.Portal>
                <DropdownMenuDrawer.Content
                    align="end"
                    side="bottom"
                    alignOffset={0}
                    className="sm:w-72 max-w-full p-4 flex-col z-full-screen">
                    <div
                        tabIndex={0}
                        role="menuitem"
                        onKeyUp={() => context.onOpenShoeBoxFileInNewTab(state.shoeboxFile)}
                        onClick={() => context.onOpenShoeBoxFileInNewTab(state.shoeboxFile)}
                        className="gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2"
                    >
                        <Icon name="MultiWindow" width={20} strokeWidth={1.5} height={20} />
                        <Text size="sm" weight="medium">Open in New Tab</Text>
                    </div>
                    <div
                        tabIndex={0}
                        role="menuitem"
                        onKeyUp={() => context.setStagedShoeBoxItemToRename(state.shoeboxFile)}
                        onClick={() => context.setStagedShoeBoxItemToRename(state.shoeboxFile)}
                        className="gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2"
                    >
                        <Icon name="Edit" width={20} strokeWidth={1.5} height={20} />
                        <Text size="sm" weight="medium">Rename</Text>
                    </div>
                    <DropdownMenu.Separator />
                    <div
                        tabIndex={0}
                        role="menuitem"
                        onKeyUp={onMoveClick}
                        onClick={onMoveClick}
                        className="gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2"

                    >
                        <Icon name="FolderMoveUp" width={20} strokeWidth={1.5} height={20} />
                        <Text size="sm" weight="medium">Move</Text>
                    </div>
                    <DropdownMenu.Separator />
                    <div
                        tabIndex={0}
                        role="menuitem"
                        onKeyUp={() => context.setStagedShoeBoxItemToDelete(state.shoeboxFile)}
                        onClick={() => context.setStagedShoeBoxItemToDelete(state.shoeboxFile)}
                        className="gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2 text-destructive"
                    >
                        <Icon name="Trash" width={20} strokeWidth={1.5} height={20} />
                        <Text size="sm" weight="medium" className="text-inherit">Delete</Text>
                    </div>
                </DropdownMenuDrawer.Content>
            </DropdownMenuDrawer.Portal>
        </DropdownMenuDrawer>
    </>
}
