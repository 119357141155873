import { ElementModifierType } from "src/backend";

export const getIconDocumentName = (args: { documentName: string, modifiers: ElementModifierType[] }) => {

    if (args.modifiers.includes('NEEDS_SIGNATURE')) {
        return `${args.documentName}.sign`;
    } else if (args.modifiers.includes('FILLABLE_FORM') ||
        args.modifiers.includes('SYSTEM_FORM')) {
        return `${args.documentName}.form`;
    }

    return args.documentName;
};