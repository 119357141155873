import { useSubscription } from "react-stomp-hooks";
import { useGetLoansViewQuery } from 'src/services/loanApi';

export const useLoans = () => {
    const loansQuery = useGetLoansViewQuery({
        categories: [
            'LEAD',
            'ORIGINATION',
            'PORTFOLIO',
            'ARCHIVE',
        ]
    });
    useSubscription(`/topic/loans`, () => {
        loansQuery.refetch()
    });


    return {
        isLoading: loansQuery.isLoading,
        allLoans: loansQuery.data ?? [],
    } as const;
}