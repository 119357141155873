import * as React from "react"

export const CalendarIcon = (props) => (
    <svg
        width={18}
        height={19}
        fill="none"
        viewBox="0 0 18 19"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className="date-picker-icon"
    >
        <path
            d="M11.5 3.33V1.665m0 1.667v1.666m0-1.666H7.75m-6.25 5V4.997a1.667 1.667 0 0 1 1.667-1.666h1.666m0-1.667v3.333M16.5 8.331V4.997a1.667 1.667 0 0 0-1.667-1.666h-.416M1.5 8.33v7.5a1.666 1.666 0 0 0 1.667 1.666h11.666a1.666 1.666 0 0 0 1.667-1.666v-7.5h-15Z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
