type OfficeType = 'Word' | 'Excel' | 'Powerpoint';


export const getOneDriveDocumentAppLabel = (extension: string): OfficeType => {
    switch (extension) {
        case 'doc':
        case 'docx':
        case 'dotx':
        case 'dot':
            return 'Word';
        case 'xlsx':
        case 'xls':
        case 'xltx':
        case 'xlt':
        case 'xlsm':
            return 'Excel';
        case 'pptx':
        case 'ppt':
        case 'potx':
        case 'pot':
            return 'Powerpoint';
        default:
            return null;
    }

};
