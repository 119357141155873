import { j as e } from "./jsx-runtime-B6kdoens.js";
import { B as a } from "./Badge-C0bj4DFU.js";
import { c as E } from "./utils-CJ9afRe1.js";
const n = (t) => /* @__PURE__ */ e.jsx(
  a,
  {
    variant: "custom",
    className: E("bg-gray-neutral-30 text-gray-neutral-70 border-none shrink-0", {
      "bg-gray-neutral-30 text-gray-neutral-70": ["READY_FOR_REVIEW_PENDING", "HOLD", "WITHDRAWN"].includes(t.status),
      "bg-red-20 text-destructive": ["REVIEW_COMPLETE_REJECTED", "IN_DEFAULT"].includes(t.status),
      "bg-yellow-5 text-yellow-90": ["REVIEW_COMPLETE_CONDITIONAL", "LEAD"].includes(t.status),
      "bg-blue-10 text-blue-100": ["READY_FOR_REVIEW_REVIEW"].includes(t.status),
      "bg-purple-5 text-purple-300": ["READY_FOR_UNDERWRITING"].includes(t.status),
      "bg-green-20 text-green-200": ["REVIEW_COMPLETE_ACCEPTED"].includes(t.status),
      "bg-yellow-20 text-yellow-400": ["IN_SERVICE"].includes(t.status),
      "bg-blue-40 text-blue-70": ["CLOSING"].includes(t.status),
      "bg-gray-table-header text-black-primary": ["ARCHIVE"].includes(t.status)
    }, t.className),
    children: t.children
  }
);
export {
  n as L
};
