// function to decode base64 string
export const decodeBase64 = (str: string) => {
    // java encoder uses spaces instead of + for some reason
    // so we need to replace them back in order to decode in javascript
    return Buffer.from(str.replace(/\s/g, '+'), 'base64').toString('binary');
}

// function to encode string to base64
export const encodeBase64 = (str: string) => {
    return Buffer.from(str, 'binary').toString('base64').replace(/\+/g, ' ');
}