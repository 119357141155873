import { useRouter } from "next/router";
import { useEffect } from "react";
import { decodeBase64 } from "src/utils/decode-base64";
import { toast, TToastType } from "src/utils/toast";

export const useQueryParamToast = () => {
    const { pathname, query, replace } = useRouter();
    const { message, status, ...rest } = query;

    useEffect(() => {
        if (!!message) {
            toast({
                content: decodeBase64(String(message)),
                type: String(status) as TToastType
            });
            replace({
                pathname: pathname,
                query: rest
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, status, pathname]);
};