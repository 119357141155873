import DropdownMenu from "components/dist/atoms/DropdownMenu";
import Stack from "components/dist/atoms/Stack";
import { ElementStatusType } from "src/backend";

import { FormElementStatusProps, useFormElementStatusState } from "./form-element-status.hook";
import { FormElementStatusAction, FormElementStatusIcon } from "./form-element-status-icon";
import { FormElementStatusRejectConfirm } from "./form-element-status-reject-confirm";


export const FormElementStatusPopover = (props: FormElementStatusProps) => {
    const state = useFormElementStatusState(props);

    const trigger = <button
        aria-label={props.status}
        aria-hidden={props.hidden}
        {...!state.hasMenu && {
            onClick: () => {
                state.handleStatusChange('ACCEPTED')
            }
        }}
        className={`hidden lg:block aria-hidden:opacity-0 data-active::group-hover:opacity-100`}
        type="button">
        <FormElementStatusIcon
            width={20}
            iconClassName="text-gray-neutral-100"
            height={20}
            status={state.mainStatusIcon} />

    </button>

    if (!state.hasMenu) {
        return trigger;
    }

    return <DropdownMenu
        modal={false}
    >
        <DropdownMenu.Trigger asChild>
            {trigger}
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
            className="min-w-40 p-2 flex flex-col gap-3"
            align="start"
            withPortal={props.withPortal}
            side="bottom"
            alignOffset={-22}>
            {state.menuItems.map((status: ElementStatusType) => (
                <DropdownMenu.Item
                    asChild
                    onSelect={(event) => {
                        state.handleStatusChange(status)
                    }}
                    key={status}
                >
                    <Stack
                        row
                        justify="start"
                        items="center"
                        space="xs">
                        <FormElementStatusIcon width={20} height={20} status={status} />
                        <FormElementStatusAction status={status} />
                    </Stack>
                </DropdownMenu.Item>
            ))}

        </DropdownMenu.Content>
        <FormElementStatusRejectConfirm
            id={props.id}
            loanId={props.loanId}
            open={state.isRejectConfirmOpen}
            onOpenChange={state.setIsRejectConfirmOpen}
            onConfirm={({ note, includeWithMessage }) => state.handleStatusChangeConfirm({
                status: 'REJECTED',
                note,
                includeWithMessage
            })}
            title={props.title}
        />
    </DropdownMenu>
}

