import { RoleLenderGroupLevel } from "src/backend";
import { useAuth } from "src/hooks/use-auth";

interface Props {
    roleGroupLevel: RoleLenderGroupLevel[];
}

export const RoleGroupLevelGuard = (props: React.PropsWithChildren<Props>) => {
    const { user } = useAuth();

    if (!props.roleGroupLevel.includes(user.loggedRoleGroup)) {
        return null;
    }
    return <>{props.children}</>;
};